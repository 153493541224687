import React, { useState } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import countries from 'countries-list';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';
import Dropdown from '../../components/molecules/Dropdown';

import mondayConfig from '../../constants/monday-config';

import '../../assets/styles/pages/inscripcion.scss';

const monthNames = [
  'Enero',
  'Febrero',
  'Marcha',
  'Abril',
  'Mayo',
  'Junio',
  'Mes de julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const FieldValidationErrorMsg = ({ field }) =>
  field ? <div className="error-val">{field.message}</div> : null;

const Inscripcion = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(null);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const watchCourse = watch('course');

  const allPrismicCourses = data.allPrismicCourses.edges;

  const getCourseDates = () => {
    switch (watchCourse) {
      case 'FELE Intensivo':
        return allPrismicCourses
          .filter(({ node: { data } }) =>
            data.show_on_these_pages.some(({ page }) => page === 'curso-fele-intensivo')
          )
          .map(({ node: { data } }) => ({
            value: format(new Date(data.from), 'MMMM do YYY', { locale: es }),
            label: format(new Date(data.from), 'd MMMM yyyy', { locale: es }),
          }));
      case 'ELE+':
        return allPrismicCourses
          .filter(({ node: { data } }) =>
            data.show_on_these_pages.some(({ page }) => page === 'curso-de-1-semana-elemas')
          )
          .map(({ node: { data } }) => ({
            value: format(new Date(data.from), 'MMMM do YYY', { locale: es }),
            label: format(new Date(data.from), 'd MMMM yyyy', { locale: es }),
          }));
      case 'Discount Spanish':
        return allPrismicCourses
          .filter(({ node: { data } }) =>
            data.show_on_these_pages.some(({ page }) => page === 'discount-spanish-lessons')
          )
          .map(({ node: { data } }) => ({
            value: format(new Date(data.from), 'MMMM do YYY', { locale: es }),
            label: format(new Date(data.from), 'd MMMM yyyy', { locale: es }),
          }));

      default:
        return monthNames.map((month) => ({ value: month, label: month }));
    }
  };
  const onSubmit = async (data) => {
    // Enable loading
    setIsLoading(true);

    try {
      const MailchimpData = {
        // EMAIL: data.userEmail,
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE4: data.userPhone, // Phone
        MMERGE5: data.courseDate.value, // Course date
        MMERGE6: format(new Date(), 'MMMM dd, yyy HH:mm', { locale: es }), // contact date - Fecha contacto format(new Date(), 'MMMM dd, yyy HH:mm', { locale: es })
        MMERGE7: data.userNative ? 'Si' : 'No', // Native
        MMERGE8: data.userNationality.value, // Nationality
        MMERGE9: format(
          new Date(data.userDobYYYY, data.userDobMM - 1, data.userDobDD),
          'dd/MM/yyyy'
        ), // DOB
        MMERGE14: data.currentLocation,
        MMERGE15: '', // accommodation
        MMERGE16: data.promotionalCode, // Promo code
        MMERGE3: data.comments, // Comments
        MMERGE10: data.course, // Course
      };
      const MondayData = {
        BOARD_ID: mondayConfig.boardEleBarcelonaLeadsId,
        GROUP_ID: mondayConfig.groupId,
        itemName: `${data.firstName} ${data.lastName}`,
        values: {
          status_3: { label: 'Formulario inscripción' }, // Source - Fuente
          status_4: { label: data.course }, // Curso
          start_date: data.courseDate.value, // Course date - Fecha inicio
          status_5: mondayConfig.status, // Estatus
          first_name: data.firstName, // Primer nombre
          last_name: data.lastName, // Apellidos
          email9: data.userEmail,
          text3: data.userPhone, // Teléfono
          // status_8: '', // accommodation - Alojamiento
          native9: { label: data.userNative ? 'Si' : 'No' }, // Nativo
          comments: data.comments, // Comentarios
          text8: `${data.userDobDD}-${data.userDobMM}-${data.userDobYYYY}`, //Fecha nacimiento
          text0: data.userNationality.value, // Nationality - Nacionalidad
          text33: data.promotionalCode, // Promo code - Código promo
          text4: data.currentLocation, // Ubicación
          date5: mondayConfig.date, // contact date - Fecha contacto
        },
      };
      const response = await fetch('/api/monday', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(MondayData),
      });
      const jsonResponse = await response.json();
      if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
        setError(false);
        addToMailchimp(data.userEmail, MailchimpData);
        setIsSuccess(true);

        if (window?.fbq) {
          window.fbq('track', 'SubmitApplication');
        }
        if (window?.gtag_report_conversion) {
          window.gtag_report_conversion();
        }
        navigate('/inscripcion/gracias');
      } else {
        throw new Error(jsonResponse.error);
      }
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setError(error ? error.message || error : 'Oops, something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        {/* <!-- Event snippet for Application form sign up conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->  */}
        <script>
          {`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-1029029840/Gst2CNe8y-sCEND_1uoD', 'event_callback': callback }); return false; } `}
        </script>
      </Helmet>

      <Layout
        title="Inscripción | ELE Barcelona"
        description="¡Conviértete en profesor de español! Completa nuestro formulario y nos pondremos en contacto contigo en menos de 12 horas. Cursos certificados. ¡Te esperamos!"
      >
        <div className="apply-form-page">
          <section className="c-first-section c-first-section--md">
            <div className="container">
              <h1 className="title">
                Completa nuestro formulario de solicitud sencillo y sin compromiso y nos pondremos
                en contacto
                <br /> contigo en menos de 12 horas
              </h1>
              <div className="wrapper">
                <div className="group">
                  <div className="group__disc">
                    <span>1</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Paso 1</p>
                    <p className="text__description">Solicitud</p>
                  </div>
                </div>

                <div className="group">
                  <div className="group__disc">
                    <span>2</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Paso 2</p>
                    <p className="text__description">Tarea de conocimiento de lenguaje</p>
                  </div>
                </div>

                <div className="group">
                  <div className="group__disc">
                    <span>3</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Paso 3</p>
                    <p className="text__description">Entrevista</p>
                  </div>
                </div>

                <div className="group">
                  <div className="group__disc">
                    <span>4</span>
                  </div>
                  <div className="group__text">
                    <p className="text__title">Paso 4</p>
                    <p className="text__description">Oferta de plaza</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="second-section">
            <div className="container">
              <form className="forms" onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                  <h1 className="c-title-22 form__title">El curso</h1>
                  <h3 className="form__subtitle">¿A qué curso quieres asistir?</h3>
                  <div>
                    <div className="form__radio">
                      <input
                        id="course-radio-1"
                        type="radio"
                        name="course"
                        value="FELE Intensivo"
                        {...register('course', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="course-radio-1">
                        <span className="label">Curso FELE Intensivo</span>
                      </label>
                    </div>
                    <div className="form__radio">
                      <input
                        id="course-radio-2"
                        type="radio"
                        name="course"
                        value="ELE+"
                        {...register('course', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="course-radio-2">
                        <span className="label">Curso ELE+</span>
                      </label>
                    </div>
                    {/* <div className="form__radio">
                      <input
                        id="course-radio-3"
                        type="radio"
                        name="course"
                        value="Discount Spanish"
                        {...register('course', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="course-radio-3">
                        <span className="label">Discount Spanish classes</span>
                      </label>
                    </div> */}
                    <FieldValidationErrorMsg field={errors?.course} />
                  </div>

                  <h3 className="form__subtitle start-date">Fecha de inicio</h3>
                  <Controller
                    name="courseDate"
                    render={({ field }) => (
                      <Dropdown
                        options={getCourseDates()}
                        placeholder="Select your preferred course date"
                        {...field}
                      />
                    )}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: 'This field is required.',
                    }}
                  />
                  <FieldValidationErrorMsg field={errors?.courseDate} />
                  <h1 className="c-title-22 form__title mt-50">Sobre ti</h1>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">Primer nombre</p>
                    <input
                      className="input__first-name"
                      type="text"
                      placeholder="Pablo"
                      name="firstName"
                      {...register('firstName', {
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors?.firstName} />
                  </label>
                  <label className="form__last-name">
                    <p className="c-text-14 text-under">Apellidos</p>
                    <input
                      className="input__last-name"
                      type="text"
                      placeholder="González"
                      name="lastName"
                      {...register('lastName', {
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors?.lastName} />
                  </label>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">Correo electrónico</p>
                    <input
                      className="input__first-name"
                      type="email"
                      placeholder="example@gmail.com"
                      name="userEmail"
                      {...register('userEmail', {
                        required: 'This field is required.',
                      })}
                    />
                    <FieldValidationErrorMsg field={errors?.userEmail} />
                  </label>
                  <label className="form__last-name phone-number">
                    <p className="c-text-14 text-under">Número de teléfono</p>
                    {/* <input type="tel" name="userPhoneType" className="begin" placeholder="+34" /> */}
                    <input
                      className="input__last-name"
                      type="tel"
                      placeholder="+34 9165 32-32"
                      name="userPhone"
                      {...register('userPhone')}
                    />
                  </label>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">Ubicación actual</p>
                    <input
                      className="input__last-name"
                      type="text"
                      placeholder="p. ej., Madrid"
                      name="currentLocation"
                      {...register('currentLocation')}
                    />
                  </label>
                  <h3 className="form__subtitle form__subtitle--my">
                    ¿El español es tu lengua materna?
                  </h3>
                  <p className="form__par">(¡Los no-nativos pueden hacer el curso también!)* </p>
                  <div>
                    <div className="form__radio">
                      <input
                        id="english-speaker-radio-1"
                        type="radio"
                        value="Yes"
                        name="userNative"
                        {...register('userNative', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="english-speaker-radio-1">
                        <span className="label">Si</span>
                      </label>
                    </div>

                    <div className="form__radio">
                      <input
                        id="english-speaker-radio-2"
                        type="radio"
                        value="No"
                        name="userNative"
                        {...register('userNative', {
                          required: 'This field is required.',
                        })}
                      />
                      <label htmlFor="english-speaker-radio-2">
                        <span className="label">No</span>
                      </label>
                    </div>
                    <FieldValidationErrorMsg field={errors?.userNative} />
                  </div>
                  <h3 className="form__subtitle start-date">Nacionalidad</h3>
                  <Controller
                    name="userNationality"
                    render={({ field }) => (
                      <Dropdown
                        options={countryNamesOptions}
                        placeholder="Selecciona tu pais"
                        isSearchable
                        {...field}
                      />
                    )}
                    control={control}
                    defaultValue={null}
                    rules={{
                      required: 'This field is required.',
                    }}
                  />
                  <FieldValidationErrorMsg field={errors?.userNationality} />

                  <div className="date">
                    <h3 className="form__subtitle">Fecha de nacimiento</h3>
                    <div className="date__wrapper">
                      <input
                        type="number"
                        placeholder="01"
                        className="input__date input__date--dd"
                        name="userDobDD"
                        {...register('userDobDD', {
                          required: 'This field is required.',
                        })}
                      />
                      <span className="text-fw-600">DD</span>
                    </div>
                    <div className="date__wrapper">
                      <input
                        type="number"
                        placeholder="02"
                        className="input__date input__date--mm"
                        name="userDobMM"
                        {...register('userDobMM', {
                          required: 'This field is required.',
                        })}
                      />
                      <span className="text-fw-600">MM</span>
                    </div>
                    <div className="date__wrapper">
                      <input
                        type="number"
                        placeholder="1987"
                        className="input__date input__date--yyyy"
                        name="userDobYYYY"
                        {...register('userDobYYYY', {
                          required: 'This field is required.',
                        })}
                      />
                      <span className="text-fw-600">YYYY</span>
                    </div>
                    <FieldValidationErrorMsg
                      field={errors?.userDobDD || errors?.userDobMM || errors?.userDobYYYY}
                    />
                  </div>

                  <label className="form__first-name promotional-code">
                    <p className="c-text-14 text-under">Código promocional</p>
                    <input
                      className="input__first-name"
                      type="text"
                      placeholder="PROMOCODE"
                      name="promotionalCode"
                      {...register('promotionalCode')}
                    />
                    {/* <button className="promotional-code__btn">Apply Promotional Code</button> */}
                  </label>

                  <label className="form__textarea">
                    <p className="c-text-14 text-under">Comentarios / preguntas</p>
                    <textarea
                      className="input__textarea input__textarea--sm"
                      placeholder="Su mensaje"
                      name="comments"
                      {...register('comments')}
                    />
                  </label>

                  <h3 className="form__subtitle">Términos y condiciones</h3>
                  <div className="form__checkbox terms">
                    <input
                      id="agree-checkbox-1"
                      type="checkbox"
                      value={true}
                      name="agreeRules"
                      {...register('agreeRules', { required: 'This field is required.' })}
                    />
                    <label htmlFor="agree-checkbox-1">
                      <span className="label">Acepto las condiciones de uso.</span>
                    </label>
                    <FieldValidationErrorMsg field={errors?.agreeRules} />
                  </div>
                  <button
                    type="submit"
                    className="c-btn c-btn--green"
                    disabled={Object.keys(errors || {}).length > 0}
                  >
                    {isLoading ? <i className="spinner" /> : 'Enviar solicitud'}
                  </button>
                </div>

                {isSuccess && (
                  <div className="success-msg">
                    <span> ¡Su solicitud ha sido enviada con éxito!</span>
                  </div>
                )}

                {isError && (
                  <div className="error-msg">
                    <span>{isError}</span>
                  </div>
                )}
                {Object.keys(errors || {}).length > 0 && (
                  <div className="error-msg">
                    <span>Algunos campos faltan o contienen errores.</span>
                  </div>
                )}
              </form>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            name {
              text
            }
          }
        }
      }
    }
  }
`;

export default Inscripcion;
